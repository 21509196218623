/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Button from "components/CustomButtons/Button";

import Box from '@material-ui/core/Box';

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Ook de ICT bij u op school kan ik naar een hoger niveau tillen!</h2>
                        <h5 className={classes.description}>
                            Of de ICT bij u op school een zorg is of al perfect op orde, ik kan in iedere situatie een verschil maken. Onderstaande diensten geven een idee van de mogelijkheden die ik kan bieden, echter iedere school is anders waardoor maatwerk nodig kan zijn. In overleg met u bespreek ik graag de mogelijkheden.
                        </h5>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                <InfoArea
                                    title="Hard- & Software"
                                    description="Huur mij in om op locatie alle medewerkers en leerlingen te ondersteunen bij het gebruik van hun computers, om problemen op te lossen (software, hardware, etc.), contacten met leveranciers te onderhouden en training te geven om uw medewerkers naar een hoger niveau te tillen op IT-gebied."
                                    icon={VerifiedUser}
                                    iconColor="success"
                                    vertical
                                />
                                <Link to="/diensten/hardwaresoftware"><Button color="info" className={classes.servicesButton} round>Hard- & Software Diensten</Button></Link>
                            </Box>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                <InfoArea
                                    title="Automatisering"
                                    description="Op scholen zie ik vaak dat er nog ruimte is voor verbetering op het gebied van automatisering. Dit is hét middel om te zorgen dat de werkdruk omlaag gaat. Hangen er nog intekenlijsten voor oudergesprekken op de deuren? Moeten leerkrachten de cijfers/scores van leerlingen in verschillende programma’s dubbel registreren? Wordt er een jaarkalender in Word of Excel gemaakt? Ik bedenk graag oplossingen om uw school efficiënter te laten werken!"
                                    icon={Settings}
                                    iconColor="info"
                                    vertical
                                />
                                <Link to="/diensten/automatisering"><Button color="info" className={classes.servicesButton} round>Automatisering Diensten</Button></Link>
                            </Box>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                <InfoArea
                                    title="Advies"
                                    icon={Assignment}
                                    description="Laat mij u adviseren over de technische kant van IT, waardoor u zich kunt richten op de onderwijskundige kant. Op basis van uw onderwijskundige wensen, stel ik een (meerjaren) plan op dat beschrijft voor welke oplossingen er gekozen kan worden, wat de kosten zijn en wat de beste keuze voor uw school is."
                                    iconColor="danger"
                                    vertical
                                />
                                <Link to="/diensten/advies"><Button color="info" className={classes.servicesButton} round>Advies Diensten</Button></Link>
                            </Box>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

ProductSection.propTypes = {
    classes: PropTypes.object
};

export default withStyles(productStyle)(ProductSection);
