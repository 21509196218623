import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import skillsPageContent from "views/SkillsPage/skillsPageContent";
import skillsPageStyle from "assets/jss/material-kit-react/views/skillsPage";


class SkillsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            albumIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { classes, ...rest } = this.props;
        const imageClasses = classNames (
            classes.imgRaised,
            classes.imgFluid,
            classes.imgRounded,
            classes.imgZoom
        );
        const { albumIndex, photoIndex, isOpen } = this.state;

        const customStyles = {
            overlay: {
                zIndex: 1200
            }
        };

        const imagesArr = [
                skillsPageContent.adviesContent[0]["images"],
                skillsPageContent.adviesContent[1]["images"],
        ];

        return (
            <div>
                <Header
                    color="transparent"
                    brand="Eerste Hulp Bij Onderwijs & ICT"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/profile-bg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h1 className={classes.title}>Diensten: Advies</h1>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={8}>
                                    <Card>
                                        <CardBody>
                                            <h4 className={classes.description}>Naast het oplossen van praktische problemen en automatiseren van processen, kan ik ook adviseren over strategische keuzes op IT-gebied en trainingen verzorgen over het gebruik van software (G Suite, educatieve/ digibordsoftware, LVS, etc.).</h4>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                    </div>
                    <div className={classes.container}>
                        {skillsPageContent.adviesContent.map(({title, description, images}, index) => (
                            <div className={classes.section}>
                                <GridContainer width="100%">
                                    <Box clone order={index%2===0 ? {} : {xs: 0, sm: 0, md: 1}}>
                                        <GridItem xs={12} sm={12} md={7}>
                                            <h2 className={classes.subtitle}>{title}</h2>
                                            <h5 className={classes.description}>{description}</h5>
                                        </GridItem>
                                    </Box>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {images ? <img src={imagesArr[index][0]} alt="..." className={imageClasses} onClick={() => this.setState({ isOpen: true, albumIndex: imagesArr[index] })} /> : null}
                                    </GridItem>
                                </GridContainer>
                            </div>
                        ))}

                    </div>
                </div>
                <Footer/>
                {isOpen && (
                    <Lightbox
                        mainSrc={albumIndex[photoIndex]}
                        nextSrc={albumIndex[(photoIndex + 1) % albumIndex.length]}
                        prevSrc={albumIndex[(photoIndex + albumIndex.length - 1) % albumIndex.length]}
                        onCloseRequest={() => this.setState({ isOpen: false, photoIndex: 0 })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + albumIndex.length - 1) % albumIndex.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % albumIndex.length,
                            })
                        }
                        reactModalStyle={customStyles}
                        enableZoom={false}
                    />
                )}
            </div>
        );
    }
}

SkillsPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(skillsPageStyle)(SkillsPage);