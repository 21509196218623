import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";
import Lightbox from 'react-image-lightbox';
import InfoArea from "components/InfoArea/InfoArea";
import Button from "components/CustomButtons/Button";
import 'react-image-lightbox/style.css';

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Duo from "@material-ui/icons/Duo";
import Assignment from "@material-ui/icons/Assignment";
import Class from "@material-ui/icons/Class";

import skillsPageStyle from "assets/jss/material-kit-react/views/skillsPage";

// Asset imports
import RemoteTeachingDiagram from 'assets/img/skillsPage/RemoteTeaching/RemoteTeachingDiagram.png';

class SkillsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            albumIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { classes, ...rest } = this.props;
        const imageClasses = classNames (
            classes.imgRaised,
            classes.imgFluid,
            classes.imgRounded,
            classes.imgZoom
        );
        const { albumIndex, photoIndex, isOpen } = this.state;

        const customStyles = {
            overlay: {
                zIndex: 1200
            }
        };

        const imagesArr = [
                [RemoteTeachingDiagram]
        ];

        return (
            <div>
                <Header
                    color="transparent"
                    brand="Eerste Hulp Bij Onderwijs & ICT"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/profile-bg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h1 className={classes.title}>Diensten: Les op afstand (Corona)</h1>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>De mogelijkheden</h2>
                                    <h5 className={classes.description}>
                                        Tegenwoordig is het prima mogelijk om zonder een al te grote investering op afstand les te kunnen geven aan uw leerlingen. Daarvoor zijn verschillende opties beschikbaar, ik richt me echter op de combinatie van G Suite - Hangouts Meet, Hangouts Chat, Google Classroom en de verwerkingssoftware voor methodes die uw school al gebruikt.
                                    </h5>
                                </GridItem>
                            </GridContainer>
                            <div>
                                <GridContainer className={classes.justifyCenter}>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                            <InfoArea
                                                title="Hangouts Chat"
                                                description="Uw leerkrachten kunnen chatten met leerlingen en/of collega’s via ieder apparaat. Er kunnen chatruimtes worden aangemaakt om met groepen tegelijk te chatten. Dit is het primaire communicatiemiddel met uw leerlingen in deze periode."
                                                icon={Chat}
                                                iconColor="success"
                                                vertical
                                            />
                                        </Box>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                            <InfoArea
                                                title="Hangouts Meet"
                                                description="Uw leerkrachten kunnen een videogesprek opzetten met de klas (de leerlingen al dan niet met webcam) óf kunnen een livestream verzorgen voor de leerlingen, waar de leerlingen alleen naar kunnen kijken, maar niet met de docent of andere leerlingen kunnen praten. Tevens kunnen vergaderingen en instructies worden opgenomen om deze met leerlingen te kunnen delen."
                                                icon={Duo}
                                                iconColor="success"
                                                vertical
                                            />
                                        </Box>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                            <InfoArea
                                                title="Google Classroom"
                                                icon={Class}
                                                description="Wellicht al wel bekend, Google Classroom is een handig systeem (ook voor normaal in de klas), om opdrachten en lesmateriaal klaar te zetten voor leerlingen. De leerlingen kunnen vervolgens hun opdrachten inleveren en de leerkracht kan deze inzien en beoordelen. Hier kunnen ook weer de opgenomen instructiefilmpjes gemaakt met Meet in worden geplaatst."
                                                iconColor="success"
                                                vertical
                                            />
                                        </Box>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                                            <InfoArea
                                                title="Verwerkingssoftware"
                                                icon={Assignment}
                                                description="Verschillende scholen gebruiken verschillende softwarepakketten om hun leerlingen opdrachten te laten maken die bij hun methode horen. Het beste is om diezelfde software te blijven gebruiken omdat uw leerlingen als het goed is al gewend zijn om daarmee te werken. Dat kan gaan om programma’s als Gynzy verwerking, Snappet, maar ook de volledig digitale varianten (verwerkingssoftware) die echt bij de methode horen. Niet iedere methode heeft dit beschikbaar, maar de meeste recentere methodes wel."
                                                iconColor="success"
                                                vertical
                                            />
                                        </Box>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Realisatie</h2>
                                    <h5 className={classes.description}>
                                        Afhankelijk van uw huidige situatie, moeten er een aantal stappen worden genomen om deze werkwijze te realiseren, daar help ik u uiteraard graag bij. Zie onderstaand schema om snel te bepalen op welke stap van het proces uw school zich nu bevindt.
                                    </h5>
                                    <img src={imagesArr[0][0]} alt="..." className={imageClasses} onClick={() => this.setState({ isOpen: true, albumIndex: imagesArr[0] })} />
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Werkt uw school al met G Suite en hebben alle medewerkers en leerlingen accounts?</h2>
                                    <h5 className={classes.description}>
                                        Dan is het activeren van Hangouts en instellen van de rechten niet veel werk en hebben uw medewerkers, leerlingen en ouders eigenlijk alleen maar de instructie nodig om aan de slag te kunnen met lesgeven op afstand. Ik zorg dat alles juist geconfigureerd is en u ontvangt de handleidingen die u onder medewerkers en ouders kunt verspreiden.
                                    </h5>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Werkt uw school al met G Suite, maar heeft nog niet iedereen een account?</h2>
                                    <h5 className={classes.description}>
                                        Sommige scholen starten met het geven van accounts aan leerlingen en niet aan medewerkers of omgekeerd. Ook in deze situatie is het niet bijzonder veel werk om aan de slag te gaan met lesgeven op afstand. Op basis van gegevens uit uw LAS maak ik de accounts compleet en voorzie ik u van lijsten met inloggegevens. Ik zorg dat alles juist geconfigureerd is en u ontvangt de handleidingen die u onder medewerkers en ouders kunt verspreiden.
                                    </h5>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Heeft uw school nog helemaal geen G Suite?</h2>
                                    <h5 className={classes.description}>
                                        Ook dan help ik graag, alleen kan ik geen garanties geven over hoe lang het zal duren voordat alles werkt. Tijdens de aanvraag van G Suite ben ik namelijk afhankelijk van hoe snel Google is op dit moment, hoe snel u (of een collega van u) de voorwaarden accepteert en ten slotte hoe lang het duurt om bepaalde instellingen aan uw domeinnaam toe te voegen zodat het domein geverifieerd kan worden en ook de e-mail correct werkt.
                                    </h5>
                                    <h5 className={classes.description}>
                                        Daarna moeten nog de rechten en instellingen binnen G Suite worden gedaan, leerlingen en medewerkers worden ingevoerd en op de hoogte gebracht van hun gebruikersnamen en wachtwoorden en er moeten handleidingen beschikbaar komen voor medewerkers, leerlingen en ouders.
                                    </h5>
                                    <h5 className={classes.description}>
                                        Heeft uw school al Office 365, kijk dan vooral eerst even of Microsoft Teams voor uw school voldoet. Heeft uw school geen Office365 of G Suite, dan is dit een prima moment om voor G Suite te kiezen en daar wat tijd en geld in te investeren. G Suite is gratis voor scholen, u betaalt alleen aan mij voor de eenmalige inrichting.
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Tarieven</h2>
                                    <h5 className={classes.description}>
                                        Voor iedere stap reken ik een vast bedrag. U kunt dus zelf aangeven welke stappen u bij mij wilt afnemen, maar ook zelf bepaalde stappen uitvoeren. Prijzen zijn ex. btw.
                                    </h5>
                                    <h5 className={classes.description}>
                                        <table>
                                            <tr>
                                                <td>Stap & Kosten
                                                </td>
                                                <td>Voorwaarden
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Handleidingen<br/>€300,-
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Ik ontvang een lijst van alle gebruikte educatieve software</li>
                                                        <li>Ik ontvang benodigde informatie om in de handleidingen te verwerken</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Inschakelen Hangouts en instellen rechten<br/>€100,-
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Ik ontvang inloggegevens van een beheer account van de G Suite omgeving</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aanmaken accounts medewerkers/leerlingen<br/>€300,-
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Toegang tot het LAS of een spreadsheet (Excel) met relevante gegevens gebruikers (voornaam, achternaam of 1e letter achternaam, klas)</li>
                                                        <li>Inloggegevens van een beheer account G Suite omgeving</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Instellen rechten G Suite<br/>€300,-
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Inloggegevens van een beheer account G Suite omgeving</li>
                                                            <li>Overlegmoment voor keuzes in de rechten</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aanvraag G Suite<br/>€300,-
                                                </td>
                                                <td>
                                                    <ul>
                                                        <li>Overlegmoment over de inrichting (keuze domeinnaam, etc.)</li>
                                                            <li>Toegang tot contactpersoon die beheer over de domeinnaam heeft, of eigen account om de domeinnaam van school te beheren.</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </table>
                                    </h5>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10}>
                                    <h2 className={classes.subtitle}>Wat u van mij kunt verwachten</h2>
                                    <h5 className={classes.description}>
                                        Wanneer u het aanvraagformulier invult, krijg ik alle gegevens binnen en stel ik een offerte op. Daar zal ik ook aangeven wanneer ik met uw school kan starten en wat de tijdlijn is voor de verschillende stappen. Nadat u een akkoord heeft gegeven op de offerte, ga ik op de afgesproken datum aan de slag.
                                    </h5>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <a href="https://forms.gle/y4p45ov65QdcBFvv6" target="_blank" rel="noopener noreferrer"><Button color="info" className={classes.servicesButton} round>Naar het aanvraagformulier</Button></a>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Footer/>
                {isOpen && (
                    <Lightbox
                        mainSrc={albumIndex[photoIndex]}
                        nextSrc={albumIndex[(photoIndex + 1) % albumIndex.length]}
                        prevSrc={albumIndex[(photoIndex + albumIndex.length - 1) % albumIndex.length]}
                        onCloseRequest={() => this.setState({ isOpen: false, photoIndex: 0 })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + albumIndex.length - 1) % albumIndex.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % albumIndex.length,
                            })
                        }
                        reactModalStyle={customStyles}
                        enableZoom={false}
                    />
                )}
            </div>
        );
    }
}

SkillsPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(skillsPageStyle)(SkillsPage);