import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import pricingPageStyle from "assets/jss/material-kit-react/views/pricingPage";

// Icons
import Business from "@material-ui/icons/Business"
import Home from "@material-ui/icons/Home"
import Commute from "@material-ui/icons/Commute"


class PricingPage extends React.Component {
    render() {
        const { classes, ...rest } = this.props;

        return (
            <div>
                <Header
                    color="transparent"
                    brand="Eerste Hulp Bij Onderwijs & ICT"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/profile-bg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h1 className={classes.title}>Tarieven</h1>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={10} md={4}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} color="info">Vrijblijvend</CardHeader>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>Bespreken we uw wensen</h4>
                                            <p>
                                                Neem contact met me op, dan bespreken we eerst telefonisch uw wensen en bepalen we of ik u daarbij kan helpen.<br />
                                                Vervolgens maken we een afspraak om uw wensen in meer detail te bespreken en ontvangt u een offerte van mij.<br />
                                                Daarna beslist u of u mij hiervoor inhuurt.
                                            </p>
                                            <Link to="/contact"><Button color="success" round>Contact</Button></Link>
                                        </CardBody>

                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={10} md={4}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader}  color="info">Uurtarief</CardHeader>
                                        <CardBody>
                                            <List>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Business />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Op locatie" secondary="€75,- per uur" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Home />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Op afstand" secondary="€65,- per uur" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Commute />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Voorrijkosten" secondary="€50,- (vervalt bij volle werkdag)" />
                                                </ListItem>
                                            </List>
                                        </CardBody>

                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={10} md={4}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader}  color="info">Vaste prijs</CardHeader>
                                        <CardBody>
                                            <h4 className={classes.cardTitle}>Is afhankelijk van de opdracht bespreekbaar</h4>
                                            <p>
                                                Voor concrete opdrachten waar ik voor weinig verassingen zal komen te staan, kunnen we een vaste prijs afspreken.<br />
                                                Voor opdrachten waarbij dit niet mogelijk is, geef ik een realistische schatting van het benodigd aantal uren en zal ik u altijd tijdig informeren indien ik daarvan af moet wijken.
                                            </p>
                                        </CardBody>

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

PricingPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(pricingPageStyle)(PricingPage);