import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

// Later weg hier
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle";

import portret from "assets/img/portret_aboutme.jpg";

//import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle";

class AboutMeSection extends React.Component {
    render() {
        const {classes} = this.props;
        const imageClasses = ClassNames (
            classes.imgRaised,
            classes.imgRounded,
            classes.imgFluid
        );
        return (
            <div className={classes.section}>
                <h2 className={classes.title}>Even voorstellen</h2>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader className={classes.cardHeader}>
                                <img src={portret} alt="..." className={imageClasses} />
                            </CardHeader>
                            <CardBody>
                                <h5 className={classes.cardTitle}>Mijn naam is Wouter van Ruyven, ik woon in Alkmaar, ben 33 jaar oud en heb samen met mijn vrouw Elisa twee prachtige kinderen.</h5>
                                <p>
                                    Al ruim 12 jaar werk ik als ICT’er in het onderwijs. Hierdoor heb ik niet alleen veel kennis en ervaring opgedaan over alle verschillende aspecten van ICT (beleid, software en netwerkbeheer), maar heb ik ook veel kennis opgedaan over lesgeven en financiën en kan ik goede trainingen geven.<br/>
                                    Dat ik in tegenstelling tot de meeste ICT’ers in het onderwijs, geen leerkracht ben die wat verstand heeft van computers, maar een ICT’er die verstand heeft van onderwijs, is wat mij zo uniek en effectief maakt.<br/>
                                    Ik heb inzicht in de processen binnen het onderwijs, begrijp hoe ICT leerlingen en leerkrachten moet ondersteunen en hoe het dat in de toekomst alleen maar meer zal doen. Daarnaast weet ik ook waar de uitdagingen en ook de frustraties zitten en hoe ICT het onderwijs in de komende jaren verder zal veranderen.

                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }

}

AboutMeSection.propTypes = {
    classes: PropTypes.object
};

export default withStyles(teamStyle)(AboutMeSection);