import { container, title, cardTitle, mainRaised } from "assets/jss/material-kit-react.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const skillsPageStyle = {
    container,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        color: "#999",
        textAlign: "left !important",
        whiteSpace: "pre-line"
    },
    name: {
        marginTop: "-80px"
    },
    ...imagesStyle,
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised,
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center",
        color: "#FFFFFF",
        zIndex: 1
    },
    subtitle: {
        ...title,
        position: "relative",
        minHeight: "32px",
        marginTop: "15px",
        fontWeight: "300px",
        textDecoration: "none",
        textAlign: "left",
        color: "#000000",
    },
    navWrapper: {
        margin: "20px auto 50px auto",
        textAlign: "center"
    },section: {
        padding: "40px 0",
        textAlign: "left"
    },
    cardTitle,
    cardHeader: {
        fontWeight: "700",
        textAlign: "center"
    },
    gridContainerContainer: {
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    imgZoom: {
        cursor: "zoom-in",
        marginTop: "90px"
    },
    justifyCenter: {
        textAlign: "center"
    },
    servicesButton: {
        width: "auto"
    }
};

export default skillsPageStyle;
