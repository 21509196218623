import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

import 'assets/scss/material-kit-react.scss';

import LandingPage from 'views/LandingPage/LandingPage';
import ContactPage from 'views/ContactPage/ContactPage';
import PricingPage from 'views/PricingPage/PricingPage';
import HardSoftSkillsPage from "./views/SkillsPage/HardSoftSkillsPage";
import AutomatiseringSkillsPage from "./views/SkillsPage/AutomatiseringSkillsPage";
import AdviesSkillsPage from "./views/SkillsPage/AdviesSkillsPage";
import RemoteTeachingPage from "./views/SkillsPage/RemoteTeachingPage"

var hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <ScrollToTop/>
        <Switch>
            <Route path="/contact" component={ContactPage} />
            <Route path="/tarieven" component={PricingPage} />
            <Route path="/diensten/hardwaresoftware" component={HardSoftSkillsPage} />
            <Route path="/diensten/automatisering" component={AutomatiseringSkillsPage} />
            <Route path="/diensten/advies" component={AdviesSkillsPage} />
            <Route path="/diensten/lesopafstand" component={RemoteTeachingPage} />
            <Route path="/" component={LandingPage} />
        </Switch>
    </Router>,
    document.getElementById('root')
);