import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import skillsPageContent from "views/SkillsPage/skillsPageContent";
import skillsPageStyle from "assets/jss/material-kit-react/views/skillsPage";


class SkillsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            albumIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { classes, ...rest } = this.props;
        const imageClasses = classNames (
            classes.imgRaised,
            classes.imgFluid,
            classes.imgRounded,
            classes.imgZoom
        );
        const { albumIndex, photoIndex, isOpen } = this.state;

        const customStyles = {
            overlay: {
                zIndex: 1200
            }
        };

        const imagesArr = [
            skillsPageContent.automatiseringContent[0]["images"],
            skillsPageContent.automatiseringContent[1]["images"],
            skillsPageContent.automatiseringContent[2]["images"],
        ];

        return (
            <div>
                <Header
                    color="transparent"
                    brand="Eerste Hulp Bij Onderwijs & ICT"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/profile-bg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h1 className={classes.title}>Diensten: Automatisering</h1>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <Card>
                                    <CardBody>
                                        <h4 className={classes.description}>Automatisering is hèt middel om uw medewerkers tijd te laten besparen en was tijd besparen niet het belangrijkste voordeel van de computer? Ondertussen lijkt het daar niet meer op, maar door processen in kaart te brengen en te automatiseren wordt het mogelijk om tijd te besparen en toch alles te registreren, overzicht te houden op de leerprestaties en de communicatie effectief te laten verlopen.
                                            Hieronder volgt een aantal voorbeelden van diensten die ik lever. Dit zijn goede en haalbare projecten die werkelijk tijd besparen. Heeft u specifieke wensen om te automatiseren op andere gebieden, dan denk ik daar graag over mee en kom ik met de meest optimale oplossing.
                                        </h4>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className={classes.container}>
                        {skillsPageContent.automatiseringContent.map(({title, description, images}, index) => (
                            <div className={classes.section}>
                                <GridContainer width="100%">
                                    <Box clone order={index%2===0 ? {} : {xs: 0, sm: 0, md: 1}}>
                                        <GridItem xs={12} sm={12} md={7}>
                                            <h2 className={classes.subtitle}>{title}</h2>
                                            <h5 className={classes.description}>{description}</h5>
                                        </GridItem>
                                    </Box>
                                    <GridItem xs={12} sm={12} md={5}>
                                        {images ? <img src={imagesArr[index][0]} alt="..." className={imageClasses} onClick={() => this.setState({ isOpen: true, albumIndex: imagesArr[index] })} /> : null}
                                    </GridItem>
                                </GridContainer>
                            </div>
                        ))}

                    </div>
                </div>
                <Footer/>
                {isOpen && (
                    <Lightbox
                        mainSrc={albumIndex[photoIndex]}
                        nextSrc={albumIndex[(photoIndex + 1) % albumIndex.length]}
                        prevSrc={albumIndex[(photoIndex + albumIndex.length - 1) % albumIndex.length]}
                        onCloseRequest={() => this.setState({ isOpen: false, photoIndex: 0 })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + albumIndex.length - 1) % albumIndex.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % albumIndex.length,
                            })
                        }
                        reactModalStyle={customStyles}
                        enableZoom={false}
                    />
                )}
            </div>
        );
    }
}

SkillsPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(skillsPageStyle)(SkillsPage);