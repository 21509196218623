import calendar1 from 'assets/img/skillsPage/Calendar/image1.png'
import calendar2 from 'assets/img/skillsPage/Calendar/image2.png'
import calendar3 from 'assets/img/skillsPage/Calendar/image3.png'

import reinstall1 from 'assets/img/skillsPage/Reinstall/image1.jpg'

import toTheCloud1 from 'assets/img/skillsPage/ToTheCloud/image1.png'

import workflow1 from 'assets/img/skillsPage/Workflow/image1.png'

import folderStructure1 from 'assets/img/skillsPage/FolderStructure/image1.png'

export default {
    hardSoftContent: [
        {
            title: "Computer (her)installatie",
            description: "Nieuwe computers gekocht en moeten deze nog worden aangesloten en geïnstalleerd? Ik doe het efficiënt en netjes. Ook oude computers zijn nog wel eens gebaat bij een herinstallatie (of hebben deze nodig i.v.m. het naderende einde van Windows 7).\n" +
            "Dit is een klus die uitermate geschikt is om uit te voeren in het weekend of tijdens een vakantie zodat uw personeel en de leerlingen er geen overlast van ondervinden.",
            images: [reinstall1]
        },
        {
            title: "Probleemoplossing",
            description: "Het oplossen van welk computerprobleem dan ook is over het algemeen mijn belangrijkste taak. De problemen die ik oplos lopen uiteen van problemen met de website of e-mail, praktische problemen met de computer, problemen met digiborden, tot de instellingen van belangrijke programma’s zoals het LVS, koppeling met bron/duo, cito LOVS, etc.\n" +
            "Spelen er allerlei ‘kleine’ probleempjes die voor veel overlast zorgen? Laat me een dagje de klassen langs lopen om direct de problemen waar het kan op te lossen en daarnaast te inventariseren wat er nog meer aan de hand is en hoe dat opgelost kan worden.\n" +
            "U kunt voor ieder probleem op IT-gebied contact met me opnemen!",
            images: false
        },
        {
            title: "Overstappen naar de cloud",
            description: "Bent u er ook al aan toe? De overstap naar de cloud is naar mijn mening een zeer goede investering voor de toekomst. Geen dure en kwetsbare servers meer in de school, maar een goed beveiligde online omgeving bij grote partijen (die deze dienst veelal gratis aan scholen bieden).\n" +
            "Laat mij u onafhankelijk adviseren over de mogelijkheden, verschillende aanbieders, de voor- en nadelen van de verschillende keuzes en de financiële consequenties die hieraan verbonden zijn.",
            images: [toTheCloud1]
        }
    ],
    automatiseringContent: [
        {
            title: "Digitale agenda",
            description: "Digitaliseer de jaarplanner van de school. Zowel in Office365 als G Suite zitten agenda’s die perfect in te zetten zijn om de volledige planning van uw school in bij te houden. Ik zet alles klaar, maak groepen aan die vaak samen afspraken hebben (bouwen, MT, werkgroepen, klassen, etc.) en maak verschillende agenda’s aan (b.v. klassen, jaarplanner, vakuren, pauzes). Indien gewenst kan ik ze vullen met afspraken, maar dat kunt u ook prima zelf.  Het resultaat is een persoonlijke agenda voor iedereen binnen het team, die altijd up-to-date is, waardoor er geen misverstanden zijn, zoals dubbele afspraken, en men geen tijd meer kwijt is aan het zoeken naar data en tijden waarop een ieder beschikbaar is.\n" +
            "Dit is een klus die, na een intakegesprek, prima op afstand te realiseren is.",
            images: [calendar1, calendar2, calendar3]
        },
        {
            title: "Workflow",
            description: "Gaat het bij u ook vaak mis met de standaard processen binnen uw school? Worden accounts voor nieuwe medewerkers of leerlingen niet tijdig gemaakt? Gaat het fout bij het afhandelen van vrijstellingsverzoeken van ouders? Ook hier heb ik een oplossing voor: Formulieren met ‘workflows’. In de afbeelding hiernaast ziet u bijvoorbeeld een eenvoudige workflow voor het aanstellen van een nieuwe medewerker. Dit proces zal er op de meeste scholen ongeveer zo uitzien. Meestal verloopt zo’n eenvoudig proces echter niet zo soepel, mailtjes over en weer met ontbrekende gegevens of wachten op iemand die eerst een actie moet uitvoeren voordat de rest uitgevoerd kan worden. Maar het is ook mogelijk om zo’n workflow te programmeren in een Google Formulier.\n" +
            "De workflow hiernaast verloopt dan ongeveer als volgt:\n" +
            "De directie stuurt de nieuwe medewerker een link waar hij/zij de NAW gegevens moet invullen en op verzenden klikt. De directie krijgt dit formulier binnen en vult deze aan met gegevens over de aanstelling (FTE, klas waar de medewerker lesgeeft, functie, etc.) en verzendt deze. Na het verzenden ontvangt de afdeling P&O het formulier met de voor hun relevante gegevens om de medewerker bij het administratiekantoor aan te melden en het contract te regelen. Tegelijkertijd ontvangt de ICT’er op school het formulier met relevante gegevens om accounts aan te maken. Nadat de ICT’er de accounts heeft aangemaakt, vult hij/zij de gebruikersnaam en wachtwoorden in op het formulier en de nieuwe medewerker ontvangt een vooraf opgesteld document met instructie voor inloggen en alle bijbehorende gebruikersnamen en wachtwoorden.",
            images: [workflow1]
        },
        {
            title: "Leerkrachtenschijf",
            description: "Is de leerkrachtenschijf/gedeelde drive/teamsite bij u op school ook een zooitje waar 100 verschillende mappen in staan waarvan driekwart niet meer gebruikt wordt en niemand kan vinden wat hij/zij zoekt? Ik heb al meerdere scholen geholpen met dit probleem. Samen bedenken we een logische mappenstructuur die aansluit op de behoeftes van de school.\n" +
            "Indien de school in de cloud werkt, is het vaak mogelijk om meerdere ‘schijven’ aan te maken, met verschillende rechten zodat er ook bijvoorbeeld een schijf is waarin de directie/IB/MT hun protocollen, regels, formats en andere documenten kwijt kunnen, zonder dat andere medewerkers deze kunnen wijzigen.",
            images: [folderStructure1]
        }
    ],
    adviesContent: [
        {
            title: "Training",
            description: "De ICT kennis van de medewerkers op school kan soms enorm verschillen. Om de kennis bij te spijkeren bij degene die het nodig hebben, geef ik op scholen regelmatige (wekelijkse) maar korte trainingen van +/- 30 min die op een specifiek onderwerp gericht zijn. Zo kunnen de medewerkers training krijgen die aansluit bij hun behoefte en is de hoeveelheid informatie niet te veel om direct weer vergeten te worden.\n" +
            "Indien u mij inhuurt om (hele dagen) op locatie aanwezig te zijn om de school te ondersteunen, kan ik van harte aanbevelen om dit te combineren met een dagelijkse training van de medewerkers van 30 minuten.\n" +
            "De meest gangbare programma’s in het onderwijs ken ik goed genoeg om hier zonder veel voorbereiding een korte training over te geven.",
            images: false
        },
        {
            title: "Advies over aanschaf hardware",
            description: "Is het weer tijd om uw computers te vernieuwen? Laat mij u adviseren!\n" +
            "Maar al te vaak zie ik dat er te veel betaald wordt voor de aanschaf van nieuwe computers, of dat er computers worden gekocht die wel een goede prijs/kwaliteit verhouding hebben, maar veel te krachtig zijn voor het doel waarvoor ze worden ingezet. Een voorbeeld hiervan is om vaste windows computers neer te zetten, terwijl er vaak voor een fractie van de prijs met Chromebooks gewerkt zou kunnen worden.\n" +
            "Bent u er niet zeker van wat u moet aanschaffen? Ik kan op verschillende manieren ondersteunen. Al vanaf €50,- controleer ik of de prijs op een offerte ongeveer klopt of dat u niet veel te ‘krachtige’ apparaten op een offerte heeft staan.\n" +
            "Heeft u geen idee welke keuze u moet maken met betrekking tot de aanschaf van hardware? Daarover kan ik u adviseren! Om alle wensen en (on)mogelijkheden te inventariseren, is een gesprek met de school noodzakelijk.",
            images: false
        }
    ]
};