import { container, title, cardTitle, mainRaised } from "assets/jss/material-kit-react.jsx";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const pricingPageStyle = {
    container,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: "#999",
        textAlign: "center !important"
    },
    name: {
        marginTop: "-80px"
    },
    ...imagesStyle,
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised,
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center",
        color: "#FFFFFF",
        zIndex: 1
    },
    subtitle: {
        ...title,
        position: "relative",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center",
        color: "#000000",
    },
    navWrapper: {
        margin: "20px auto 50px auto",
        textAlign: "center"
    },section: {
        padding: "70px 0",
        textAlign: "center"
    },
    cardTitle,
    cardHeader: {
        fontWeight: "700"
    }

};

export default pricingPageStyle;
