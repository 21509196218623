/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections
import ServicesSection from "./Sections/ServicesSection";
import AboutMeSection from "./Sections/AboutMeSection"

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
        <div>
          <Header
              color="semiTransparent"
              routes={dashboardRoutes}
              brand="Eerste Hulp Bij Onderwijs & ICT"
              rightLinks={<HeaderLinks />}
              fixed
              changeColorOnScroll={{
                height: 400,
                color: "white"
              }}
              {...rest}
          />
          <Parallax filter image={require("../../assets/img/landing-bg.jpg")}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h1 className={classes.title}>Een ICT'er die uw school de 21e eeuw in helpt?</h1>
                  <h4 className={classes.fluidSubTitle}>
                      Al ruim 12 jaar werk ik als ICT’er op veel verschillende scholen en schooltypes (regulier, SBO, ZML, PRO) en op bestuursniveau.
                      Ik weet alles van netwerkbeheer (wifi, servers, hardware, infrastructuur), educatieve software en leerlingvolgsystemen, werken in de cloud, 21e eeuwse vaardigheden, websites en automatiseren.
                      Bovendien praat ik inhoudelijk mee over leeropbrengsten, (digitale) toetsen en volledig digitaal verwerken.

                  </h4>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
                <ServicesSection />
                <AboutMeSection />
            </div>
          </div>
          <Footer />
        </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(LandingPage);
