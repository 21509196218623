import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax";
import Button from "components/CustomButtons/Button";

import contactPageStyle from "assets/jss/material-kit-react/views/contactPage";

// Icons
import Phone from "@material-ui/icons/Phone"
import Email from "@material-ui/icons/Email"

class ContactPage extends React.Component {
    render() {
        const { classes, ...rest } = this.props;


        return (
            <div>
                <Header
                    color="transparent"
                    brand="Eerste Hulp Bij Onderwijs & ICT"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/profile-bg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h1 className={classes.title}>Contact</h1>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.section}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={5}>
                                    <h2 className={classes.subtitle}>Interesse gewekt? Neem contact met me op!</h2>
                                    <h4>Denkt u net als ik, dat ik uw organisatie vooruit kan helpen? Neem dan contact met me op! Dat kan op de volgende manieren:
                                    </h4>
                                    <p>
                                        <Button color="info" href="tel:+31629538712" round><Phone />06-29538712</Button>
                                        <Button color="info" href="mailto:w.ruyven@outlook.com" round><Email />w.ruyven@outlook.com</Button>
                                    </p>
                                    <h4 className={classes.subtitle}>Overige gegevens</h4>
                                    <p>
                                        EHBOICT<br/>
                                        Hagedoornweg 16<br/>
                                        1871SE Schoorl<br/>
                                        <br/>
                                        KVK nummer: 60827947<br/>
                                        BTW nummer: NL002189408B78
                                    </p>
                                    <iframe
                                        title="Werkgebied Map"
                                        src="https://www.google.com/maps/d/embed?mid=1CmUlxIWkYSjduVhG1x7v4wSgKBIL9CFf" width="100%" height="480">
                                    </iframe>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} />
                                <GridItem xs={12} sm={12} md={5}>
                                    <iframe
                                        title="Contactformulier"
                                        src="https://docs.google.com/forms/d/e/1FAIpQLScaoSEnE3Sc2kx43hEFR3yHRVa71hED_kFskfKXcnTNerpH5g/viewform?embedded=true"
                                        width="100%" height="1000px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                                    </iframe>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

ContactPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(contactPageStyle)(ContactPage);